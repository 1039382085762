import React from 'react'
import '../../../../assets/styles/sass/glide/showcase/showcase-one.min.css'

const ShowCaseOne = () => {
  return (
    <div className='showcase_container dark'>
      <div className='showcase-one'>
        <div className='col-xs-12'>
          <h1>
            We Want To Buy Your House
            <br></br>
            <span>No Matter Your Reasons For Selling!</span>
          </h1>
          <h2>Are You In One Of These Tough Spots:</h2>
        </div>

        <div className='col-sm-6'>
          <ul className='live type2'>
            <li>Want to sell your home without listing it</li>
            <li>
              Worried your <b>vacant</b> home will be vandalised
            </li>
            <li>You need to relocate due to new job</li>
            <li>Getting a divorce</li>
          </ul>
        </div>
        <div className='col-sm-6'>
          <ul className='live type2'>
            <li>
              You need to <b>avoid</b> foreclosure
            </li>
            <li>
              Being a <b>landlord</b> is way too annoying and stressful
            </li>
            <li>Repairs cost an arm and a leg</li>
            <li>Mold, water, fire damage</li>
          </ul>
        </div>
        <div className='col-sm-12'>
          <h4>
            Fret not! We've assisted several families in similar situations. We
            have the experience you need to solve your problems!
          </h4>
        </div>
        {/* <h4>
          Stop worrying. We have helped many people in similar situations. We
          know what we are doing. We know how to solve these problems.
        </h4> */}
      </div>

      {/* 
      <div className='section section-do' id='whatwedo'>
          <div className='container'>
            <div className='col-md-8 col-md-offset-2 text-center'>
              <h2 className='title'>Area of expertise</h2>
              <div className='space-50'></div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <div className='info'>
                  <div className='icon icon-info icon-round icon-lg'>
                    <i className='nc-icon nc-single-copy-04'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>Rehab &amp; Flip </h4>
                    <h5 className='desc'>
                      Rehabbing is a popular real estate exit strategy that
                      involves renovating a property and selling it for a
                      profit. Investors who are new to real estate can start
                      with smaller rehab projects before taking on more complex
                      renovations.
                    </h5>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='info'>
                  <div className='icon icon-danger icon-round icon-lg'>
                    <i className='nc-icon nc-palette'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>Rental &amp; Lease Option</h4>
                    <h5 className='desc'>
                      A lease option is a type of contract used in both
                      residential and commercial real estate. In a lease-option,
                      a property owner and tenant agree that, at the end of a
                      specified rental period for a given property, the renter
                      has the option of purchasing the property.
                    </h5>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='info'>
                  <div className='icon icon-success icon-round icon-lg'>
                    <i className='nc-icon nc-spaceship'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>Contract for Deed</h4>
                    <h5 className='desc'>
                      A Contract for Deed is a tool that can allow buyers who
                      either don't qualify for traditional lending options or
                      who want a faster financing option to purchase property.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     */}
    </div>
  )
}
export default ShowCaseOne
