import React from 'react'
import NavigationBar from '../components/NavigationBar'
import KwikForm from '../components/universal/KwikForm'
import Footer from '../components/universal/Footer'

const Buyer = () => {
  return (
    <div>
      <div className='form_header brown'>
        <NavigationBar flipped={true} />
        <div className='container'>
          <h1>
            YOU MUST SPEND MONEY, <br></br>TO MAKE MONEY
          </h1>
          <p>
            Your next <br></br>investment property<br></br>is here!
          </p>
        </div>
      </div>

      <KwikForm
        type='buyer'
        headline='Browse an Exclusive List of Homes with Excellent ARVs'
        subHeadline='Fill Out The Quick Form Below To Access The List'
      />
      <Footer />
    </div>
  )
}
export default Buyer
