import * as THREE from 'three'
import sprite2_img from '../three/textures/sprites/snowflake2.png'

// import pc from '../three/examples/3d-obj-loader/assets/R2D2_Diffuse.jpg'
// import pc from './textures/sprites/smoke.png'
import pc from './textures/sprites/snowflake2.png'

let scene, camera, renderer
let startGeometry
let stars
var mouseX = 0,
  mouseY = 0
var windowHalfX = window.innerWidth / 2
var windowHalfY = window.innerHeight / 2
const init = mount => {
  scene = new THREE.Scene()

  const aspectRatio = mount.current.offsetWidth / mount.current.offsetHeight
  camera = new THREE.PerspectiveCamera(50, aspectRatio, 0.1, 1000)
  camera.position.z = 5
  camera.rotation.y = Math.PI / 2
  camera.rotation.x = Math.PI / 2

  renderer = new THREE.WebGLRenderer({ antialias: true })
  renderer.setSize(mount.current.offsetWidth, mount.current.offsetHeight)
  mount.current.appendChild(renderer.domElement)

  startGeometry = new THREE.Geometry()

  var ambientLight = new THREE.AmbientLight(0xcccccc, 0.4)
  scene.add(ambientLight)

  var pointLight = new THREE.PointLight(0xffffff, 0.8)
  camera.add(pointLight)
  scene.add(camera)

  for (let i = 0; i < 5000; i++) {
    //   sd
    const star = new THREE.Vector3(
      Math.random() * 600 - 300,
      Math.random() * 600 - 300,
      Math.random() * 600 - 300
    )

    star.velocity = 0
    star.acceleration = 0.002
    // star.acceleration = 0.0002
    startGeometry.vertices.push(star)
  }

  const textureLoader = new THREE.TextureLoader()

  function generateSprite () {
    var canvas = document.createElement('canvas')
    canvas.width = 16
    canvas.height = 16

    var context = canvas.getContext('2d')
    var gradient = context.createRadialGradient(
      canvas.width / 2,
      canvas.height / 2,
      0,
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2
    )

    gradient.addColorStop(0, 'rgba(255,255,255,1)')
    gradient.addColorStop(0.2, 'rgba(0,255,255,1)')
    gradient.addColorStop(0.4, 'rgba(0,0,64,1)')
    gradient.addColorStop(1, 'rgba(0,0,0,1)')

    context.fillStyle = gradient
    context.fillRect(0, 0, canvas.width, canvas.height)

    var texture = new THREE.Texture(canvas)
    texture.needsUpdate = true
    return texture
  }
  var material = new THREE.ParticleBasicMaterial({
    color: 0xffffff,
    size: 3,
    transparent: true,
    blending: THREE.AdditiveBlending,
    map: generateSprite()
  })

  stars = new THREE.Points(startGeometry, material)
  scene.add(stars)

  mount.current.addEventListener('mousemove', onDocumentMouseMove, false)
  mount.current.addEventListener('touchstart', onDocumentTouchStart, false)
  mount.current.addEventListener('touchmove', onDocumentTouchMove, false)
  window.addEventListener('resize', onWindowResize, false)

  animate()
}
function onWindowResize () {
  windowHalfX = window.innerWidth / 2
  windowHalfY = window.innerHeight / 2

  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()

  renderer.setSize(window.innerWidth, window.innerHeight)
}

function onDocumentMouseMove (event) {
  mouseX = event.clientX - windowHalfX
  mouseY = event.clientY - windowHalfY
}

function onDocumentTouchStart (event) {
  if (event.touches.length === 1) {
    event.preventDefault()

    mouseX = event.touches[0].pageX - windowHalfX
    mouseY = event.touches[0].pageY - windowHalfY
  }
}

function onDocumentTouchMove (event) {
  if (event.touches.length === 1) {
    event.preventDefault()

    mouseX = event.touches[0].pageX - windowHalfX
    mouseY = event.touches[0].pageY - windowHalfY
  }
}

const animate = () => {
  var time = Date.now() * 0.00005

  // camera.lookAt(scene.position)

  startGeometry.vertices.forEach(p => {
    p.velocity += p.acceleration
    p.y -= p.velocity
    // restart if behind screen
    if (p.y < -200) {
      p.y = Math.random() * 200 - 1
      p.velocity = 0
    }
  })

  startGeometry.verticesNeedUpdate = true
  stars.rotation.x -= 0.00182
  stars.rotation.y -= 0.00182
  // stars.rotation.z += 0.000182
  camera.rotation.x += 0.0000182
  camera.rotation.y += 0.0010082
  // camera.rotation.z += 0.00182
  // camera.rotation.y += Math.random() * 0.0002 - 0.001
  //
  renderer.render(scene, camera)
  requestAnimationFrame(animate)
}

export { init }
