import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import Home from './screens/Home'
import Buyer from './screens/Buyer'
import Seller from './screens/Seller'

import '../../assets/styles/sass/glide/base.css'

const Glide = () => {
  return (
    <BrowserRouter>
      <Route exact path='/' component={Home} title='Home' />
      <Route exact path='/buyer' component={Buyer} title='Buyer' />
      <Route exact path='/seller' component={Seller} title='Seller' />
    </BrowserRouter>
  )
}

export default Glide
