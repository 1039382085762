import React from 'react'
// import '../../../../assets/styles/sass/glide/footer/footer.min.css'

const Footer = () => {
  return (
    <div>
      <footer className='footer_container'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-6'>
              <h6>About</h6>
              <div className=''>
                <p>
                  Kwik Flip - REI<br></br>
                  (562) 212-8377<br></br>
                  65 Pine Ave #865, Long Beach, CA 90802
                </p>
                {/* <a
              href='mailto:kwikfliprei@gmail.com'
              target='_blank' rel="noopener noreferrer"
              rel='nofollow noopener noreferrer'
            >
              <p>Contact Us</p>
            </a> */}
              </div>
              <br></br>
              <p className='text-justify'>
                KwikFlip - REI is a company that purchases, rehabs, and then
                sells houses at a profit. Offers are made to sellers based on
                market value and repairs needed. KwikFlip - REI, will do
                everything possible to bring forth the highest possible offer to
                give the seller the most benefit from dealing with a fast sale.
              </p>
            </div>

            <div className='col-xs-6 col-md-3'>
              <h6>Categories</h6>
              <ul className='footer-links'>
                <li>
                  <a href='https://www.kwikfliprei.com'>
                    <p>Rehab &amp; Flip</p>
                  </a>
                </li>
                <li>
                  <a href='https://www.kwikfliprei.com'>
                    <p>Rental &amp; Lease Option</p>
                  </a>
                </li>
                <li>
                  <a href='https://www.kwikfliprei.com/'>
                    <p>Contract for Deed</p>
                  </a>
                </li>
              </ul>
            </div>

            <div className='col-xs-6 col-md-3'>
              <h6>Quick Links</h6>
              <ul className='footer-links'>
                <li>
                  <a href='https://www.kwikfliprei.com'>
                    <p> Get A Cash Offer Today</p>
                  </a>
                </li>
                <li>
                  <a href='https://www.kwikfliprei.com'>
                    <p> Looking to buy?</p>
                  </a>
                </li>
                <li>
                  <a href='https://www.kwikfliprei.com/'>
                    <p>Privacy Policy</p>
                  </a>
                </li>
                <li>
                  <a
                    href='mailto:kwikfliprei@gmail.com'
                    target='_blank'
                    rel='nofollow noopener noreferrer'
                  >
                    <p>Contact Us</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 col-sm-6 col-xs-12'>
              <p className=''>
                © 2020 KwikFlip - REI - Powered by
                <a
                  href='http://losethequit.herokuapp.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span> </span>
                  LoseTheQuit
                </a>
              </p>
            </div>

            <div className='col-md-4 col-sm-6 col-xs-12'>
              <ul className='social-icons'>
                <li>
                  <a className='facebook' href='#'>
                    <i className='fa fa-facebook'></i>
                  </a>
                </li>
                <li>
                  <a className='twitter' href='#'>
                    <i className='fa fa-twitter'></i>
                  </a>
                </li>
                <li>
                  <a className='dribbble' href='#'>
                    <i className='fa fa-dribbble'></i>
                  </a>
                </li>
                <li>
                  <a className='linkedin' href='#'>
                    <i className='fa fa-linkedin'></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
export default Footer
