import React, { useState, useEffect, useRef } from 'react'
import publicIp from 'public-ip'
import { Link } from 'react-router-dom'

// import { init } from '../../three/initial'
// import { init, animate } from '../../three/idk'
// import { init, animate } from '../../three/space'
import { init, animate } from '../../three/season_three'

const live = window.location.href.includes('kwikfliprei.com')

const KwikForm = props => {
  const { type, headline, subHeadline } = props
  let mount = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    // init(mount)
    // animate()
  }, [])

  const handleInputChange = e => {
    const { name, value } = e.target
    console.log(value)
    console.log([name])
    setValues({ ...values, [name]: value })
    console.log(values)
  }

  const postForm = async () => {
    try {
      const dataToSend = { ...values }
      const ip = await publicIp.v4()

      console.log('IP-ADDRESS')
      console.log(ip)

      dataToSend.ip = ip
      dataToSend.site = 'KF'
      dataToSend.referrer = document.referrer
      dataToSend.vendor = navigator.vendor
      dataToSend.userAgent = navigator.userAgent
      dataToSend.platform = navigator.platform
      dataToSend.language = navigator.language
      dataToSend.hostname = window.location.hostname
      dataToSend.referrer = document.referrer ? document.referrer : 'Direct'
      dataToSend.type = type
      dataToSend.live = live

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend)
      }

      const fetchURl = live
        ? `https://kwikflip-api.herokuapp.com/kf-${type}`
        : `http://localhost:2020/kf-${type}`

      const postResponse = await fetch(fetchURl, requestOptions)
      const response = await postResponse.json()
      console.log(response)
      response.success && setIsModalOpen(!isModalOpen)
    } catch (error) {
      console.log(error)
    }
  }

  // Declare our state variable called values
  // Initialize with our default values

  const [values, setValues] = useState({
    first: '',
    last: '',
    phone: '',
    email: '',
    street: '',
    city: '',
    state: '',
    zip: ''
  })

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <div>
      <div className='form_container'>
        {/* <div className='three_d_container' ref={mount}></div> */}
        <div className='container'>
          <div className='col-xs-12'>
            <h2>{headline}</h2>
            <h3>{subHeadline}</h3>
          </div>

          <div
            id='myModal'
            className='modal'
            style={{ display: isModalOpen ? 'block' : 'none' }}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <span className='close' onClick={() => handleToggleModal()}>
                  &times;
                </span>
                <h2>Thank you!</h2>
              </div>
              <div className='modal-body'>
                <p>
                  Your request for more information has been received and a
                  member of Kwik Flip will get back to you as soon as possible.
                </p>
              </div>
              <div className='modal-footer'>
                <div className='col-xs-12'>
                  <div className='ghost_button_container'>
                    <button
                      className='ghost_button hvr-grow'
                      onClick={() => handleToggleModal()}
                    >
                      Close
                    </button>
                    <span className='clear' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6'>
            <label>First Name</label>
            <input
              name='first'
              type='text'
              onChange={handleInputChange}
              value={values.first}
              required
            />
          </div>
          <div className='col-sm-6'>
            <label>Last Name</label>
            <input
              name='last'
              type='text'
              onChange={handleInputChange}
              value={values.last}
              required
            />
          </div>
          <div className='col-sm-6'>
            <label>Phone Number</label>
            <input
              name='phone'
              type='text'
              onChange={handleInputChange}
              value={values.phone}
              required
            />
          </div>
          <div className='col-sm-6'>
            <label>Email</label>
            <input
              name='email'
              type='text'
              onChange={handleInputChange}
              value={values.email}
              required
            />
          </div>
          {type === 'seller' && (
            <div>
              <div className='col-sm-6'>
                <label>Street</label>
                <input
                  name='street'
                  type='text'
                  onChange={handleInputChange}
                  value={values.street}
                  required
                />
              </div>
              <div className='col-sm-6'>
                <label>City</label>
                <input
                  name='city'
                  type='text'
                  onChange={handleInputChange}
                  value={values.city}
                  required
                />
              </div>
              <div className='col-sm-6'>
                <label>State</label>
                <input
                  name='state'
                  type='text'
                  onChange={handleInputChange}
                  value={values.state}
                  required
                />
              </div>
              <div className='col-sm-6'>
                <label>Zip Code</label>
                <input
                  name='zip'
                  type='text'
                  onChange={handleInputChange}
                  value={values.zip}
                  required
                />
              </div>
            </div>
          )}

          <div className='col-xs-12'>
            <div className='ghost_button_container'>
              <button className='ghost_button hvr-grow' onClick={postForm}>
                Submit
              </button>
              <span className='clear' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default KwikForm
