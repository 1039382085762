import React from 'react'

import Jumbotron from '../components/Jumbotron'
import ShowCaseOne from '../components/home/ShowCaseOne'
import ShowCaseTwo from '../components/home/ShowCaseTwo'
// import ShowCaseThree from '../components/home/ShowCaseThree'
// import ShowCaseFour from '../components/home/ShowCaseFour'
import Footer from '../components/universal/Footer'

const Home = () => {
  return (
    <React.Fragment>
      <Jumbotron />
      <ShowCaseOne />
      <ShowCaseTwo />

      {/* <ShowCaseThree /> */}
      {/* <ShowCaseFour /> */}

      <Footer />
    </React.Fragment>
  )
}

export default Home
