import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const NavigationBar = props => {
  const { flipped } = props
  const [menuToggle, setMenuToggle] = useState(false)

  const handleToggleMenu = () => {
    setMenuToggle(!menuToggle)
  }

  return (
    <div>
      <div
        className={`nav_overlay`}
        style={{ display: `${menuToggle ? 'block' : 'none'}` }}
      >
        <div className='wrap'>
          <ul className='wrap-nav'>
            {/* <li>
              <Link to='/'>Home</Link>
              <ul>
                <li>
                  <Link to='#'>Web</Link>
                </li>
                <li>
                  <Link to='#'>Graphic</Link>
                </li>
                <li>
                  <Link to='#'>Apps</Link>
                </li>
              </ul>
            </li> */}
            <li>
              <Link to='/seller'>
                <h3>Sellers</h3>
              </Link>
              <ul>
                <li>
                  <Link to='/seller'>
                    <p>Preforeclosure</p>
                  </Link>
                </li>
                <li>
                  <Link to='/seller'>
                    <p>Tax Liens</p>
                  </Link>
                </li>
                <li>
                  <Link to='/seller'>
                    <p>Exhausted Landlord</p>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to='/buyer'>
                <h3>Buyers</h3>
              </Link>
              <ul>
                <li>
                  <Link to='/buyer'>
                    <p>Rehab &amp; Flip</p>
                  </Link>
                </li>
                <li>
                  <Link to='/buyer'>
                    <p>Rental &amp; Lease Option</p>
                  </Link>
                </li>
                <li>
                  <Link to='/buyer'>
                    <p>Contract for Deed</p>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <div className='social'>
            <Link to='#'>
              <div className='social-icon'>
                <i className='fa fa-facebook'></i>
              </div>
            </Link>
            <Link to='#'>
              <div className='social-icon'>
                <i className='fa fa-twitter'></i>
              </div>
            </Link>
            <Link to='https://www.instagram.com/kwikflip_rei/' target='_blank'>
              <div className='social-icon'>
                <i className='fa fa-instagram'></i>
              </div>
            </Link>
            <Link to='#'>
              <div className='social-icon'>
                <i className='fa fa-behance'></i>
              </div>
            </Link>
            <Link to='#'>
              <div className='social-icon'>
                <i className='fa fa-dribbble'></i>
              </div>
            </Link>
            <p>
              <Link to=''>
                <i className='fa fa-home'></i>
              </Link>
            </p>
          </div>
        </div>
      </div>

      <nav className={`hamburger_menu_container`}>
        <div className='button'>
          <Link
            to='#'
            className={` 
              ${menuToggle ? 'btn-close' : 'btn-open'}
              ${flipped ? `btn-dark` : 'btn-light'} 
              `}
            onClick={() => handleToggleMenu()}
          />
        </div>
      </nav>

      <div className={`nav_bar_container ${flipped && `nav_flipped`}`}>
        <div className='col-sm-12'>
          <Link to=''>
            <div className='col-sm-4'>
              <div className='left_log_box'>KWIK</div>
              <div className='right_log_box'>
                <span className='right_log_box_text'>FLIP</span>
              </div>
            </div>
          </Link>

          <div className='col-sm-8'>
            <div className='nav_bar_right'>
              <div className='col-sm-3'>
                <Link to='seller'>SELLERS</Link>
              </div>
              <div className='col-sm-3'>
                <Link to='buyer'>BUYERS</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavigationBar
