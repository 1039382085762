import React from 'react'
import KwikForm from '../universal/KwikForm'

const ShowCaseTwo = () => {
  return (
    <KwikForm
      type='seller'
      headline='Want To See What We Can Buy Your House For?'
      subHeadline='Fill Out The Quick Form Below To Get A No-Hassle No-Obligation All-Cash
        Offer'
    ></KwikForm>
  )
}
export default ShowCaseTwo
