import React from 'react'

const Home = () => {
  return (
    <div>
      <div className='jumbotron'>
        <video
          className='absoluteJumbotronContainer'
          src='https://s3.amazonaws.com/corne.info/web-vp9.webm'
          autoPlay
          loop
        />
        <div className='jumbotron_content container-fluid'>
          <div className='nav_bar_container'>
            <div className='col-xs-12'>
              <div>
                <div className='left_log_box'>KWIK</div>
                <div className='right_log_box'>
                  <span className='right_log_box_text'>FLIP</span>
                </div>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='jumbotron_body'>
              <h1>THIS PAGE</h1>
              <h1>IS UNDER</h1>
              <h1>CONSTRUCTION</h1>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <footer className=''>
        <div className='container'>
          <p>KwikFlip - REI</p>
          <p>
            <a href='mailto:kwikfliprei@gmail.com'>Contact Us</a>
          </p>
        </div>
      </footer> 
      */}
    </div>
  )
}

export default Home
