import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import NavigationBar from '../components/NavigationBar'
import { init } from '../three/space'

const Jumbotron = () => {
  let mount = useRef(null)

  useEffect(() => {
    init(mount)
  }, [])

  return (
    <div className='form_container temp_container'>
      <div className='three_d_container' ref={mount}></div>

      <div className='jumbotron_container'>
        <div className='jumbotron'>
          <div className='jumbotron_content'>
            <NavigationBar></NavigationBar>

            <div className='jumbotron_body'>
              <h1>
                WE BUY <br></br>HOUSES
              </h1>
              <p>
                Get a cash offer you can trust <br></br>in 7 minutes!
              </p>
              <Link to='seller'>
                <button
                  className='ghost_button draw meet'
                  style={{ float: 'left' }}
                >
                  Get a Cash Offer
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Jumbotron
