import React, { useEffect } from 'react'
import UnderConstruction from '../under_construction'
import Glide from '../glide'
import publicIp from 'public-ip'
import config from '../../config'
import '../../../src/assets/styles/sass/glide/showcase/showcase-two.min.css'

const live = window.location.href.includes('kwikfliprei.com')

const Dispatch = () => {
  const trafficSpy = async () => {
    try {
      const dataToSend = {}
      const ip = await publicIp.v4()

      console.log('IP-ADDRESS')
      console.log(ip)

      // dataToSend.ip = ip
      dataToSend.ip = ip
      dataToSend.site = 'KF'
      dataToSend.referrer = document.referrer
      dataToSend.vendor = navigator.vendor
      dataToSend.userAgent = navigator.userAgent
      dataToSend.platform = navigator.platform
      dataToSend.language = navigator.language
      dataToSend.hostname = window.location.hostname
      dataToSend.referrer = document.referrer ? document.referrer : 'Direct'
      dataToSend.live = live

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend)
      }

      const fetchURl = live
        ? 'https://kwikflip-api.herokuapp.com/kwik-spy'
        : 'http://localhost:2020/kwik-spy'

      const postResponse = await fetch(fetchURl, requestOptions)
      const response = await postResponse.json()
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    trafficSpy()
  })

  const renderViewStream = () => {
    switch (config.template) {
      case 'construction':
        return <UnderConstruction />
      case 'glide':
        return <Glide />
      default:
        console.log(`Sorry, cannot render ${config.template}.`)
    }
  }

  return renderViewStream()
}

export default Dispatch
