import React from 'react'
import NavigationBar from '../components/NavigationBar'
import KwikForm from '../components/universal/KwikForm'
import Footer from '../components/universal/Footer'

const Seller = () => {
  return (
    <div>
      <div className='form_header other'>
        <NavigationBar flipped={true} />
        <div className='container'>
          <h1>We Deliver Faster &amp; Easier Results</h1>
          <p>
            Sell Your House Without A Realtor<br></br>AS IS - For Cash
            <br></br>100% FREE!
          </p>
        </div>
      </div>

      <KwikForm
        type='seller'
        headline='Want To See What We Can Buy Your House For?'
        subHeadline='Fill Out The Quick Form Below To Get A No-Hassle No-Obligation All-Cash
        Offer'
      />
      <Footer />
    </div>
  )
}
export default Seller
